<template>
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="centered">
					<div class="centered__wrap">
						<div class="textbox">
							<h3 class="textbox__title">
								{{t('10219')}}
							</h3>
							<p class="textbox__description" v-html="t('10220')">
							</p>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm" @click="submit">{{t('10143')}}</button>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>


<script>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup() {
		const router = useRouter()
		const { t }= useI18n() //번역필수 모듈

		return {
			submit() {
				router.push("/");
			},
			t,
			i18n,
		}
	},
}
</script>